import React from 'react';
import DuplicateAppIcon from 'remixicon-react/FileCopyLineIcon';
import LinkIcon from 'remixicon-react/LinkIcon';
import GroupIcon from 'remixicon-react/Group2LineIcon';
import HotelLineIcon from 'remixicon-react/HotelLineIcon';
import ArchiveIcon from 'remixicon-react/InboxArchiveLineIcon';
import UnarchiveIcon from 'remixicon-react/InboxUnarchiveLineIcon';
import PlayIcon from 'remixicon-react/PlayFillIcon';
import LightbulbFlashLineIcon from 'remixicon-react/LightbulbFlashLineIcon';
import UploadCouldLineIcon from 'remixicon-react/UploadCloud2LineIcon';
import FlashlightIcon from 'remixicon-react/FlashlightFillIcon';
import FolderIcon from 'remixicon-react/FolderLineIcon';

import { LazyApp } from '../api/generated/index';
import {
  BUILDER_VIEW_MOVE_APP_TO_TEAM_BUTTON,
  BUILDER_VIEW_MOVE_APP_TO_FOLDER_BUTTON,
} from '../constants';
import { useAuthStore } from '../store/auth';
import { useChatStore } from '../store/chat';
import { useOrganizationStore } from '../store/organization';
import { MessageContent, MessageSourceType, MessageType, TextMessageContent } from '../types';
import { useAppActions } from './appActionsHook';
import MenuLabelItem from './base/MenuLabelItem';
import {
  PROMPT_SUGGEST_3_SMALL_FEATURES,
  REACT_APP_ENABLE_PROMPT_SUGGEST_3_SMALL_FEATURES,
} from './constants';
import { suggestSmallFeatures } from './promptSubmissionUtils';
import RocketIcon from 'remixicon-react/Rocket2LineIcon';
import { RichPromptAction } from '../components/chat/RichPromptActionList';
import { copyToClipboard } from '../utils/clipboard';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { upgradeToProButtonHandler } from './UpgradeToProButton';
import { isMobileDevice } from '../utils/deviceDimensions';

const shouldDisplayHintToSuggestSmallFeatures = (): boolean => {
  let lastMessageWasMakeCodeRobust = false;

  if (!REACT_APP_ENABLE_PROMPT_SUGGEST_3_SMALL_FEATURES) {
    return false;
  }

  const messages = Array.from(useChatStore.getState().messages);
  if (messages.length > 0) {
    const lastUserMessage = messages
      .reverse()
      .find((message) => message.source.type === MessageSourceType.User);

    // Type guard.
    const isTextMessageContent = (
      messageContent: MessageContent
    ): messageContent is TextMessageContent => {
      return messageContent.type === MessageType.Text;
    };

    if (
      lastUserMessage &&
      'type' in lastUserMessage.content &&
      isTextMessageContent(lastUserMessage.content) &&
      lastUserMessage.content.text === PROMPT_SUGGEST_3_SMALL_FEATURES
    ) {
      lastMessageWasMakeCodeRobust = true;
    }
  }

  return !lastMessageWasMakeCodeRobust;
};

// eslint-disable-next-line max-lines-per-function, max-statements
export const useChatAppActionsList = () => {
  const {
    makeCommunityTemplateFromApp,
    createTestAppForTemplate,
    archiveApp,
    unarchiveApp,
    copyApp,
  } = useAppActions();
  const { isLazyEmployee, userPermissions } = useAuthStore();
  const { userOrganizationRoleAssignments, appFolders } = useOrganizationStore();

  // eslint-disable-next-line max-lines-per-function, max-statements
  const getAvailableChatBarActionsList = (
    app: LazyApp | null,
    appHasCode: boolean,
    onSuccess: () => Promise<void>,
    onTestYourAppActionClick: () => void,
    onPublishClick: () => void,
    emitResponseReceivedEvent: (correlationId) => void
    // eslint-disable-next-line max-params
  ): RichPromptAction[] => {
    const codePublishActions: RichPromptAction[] = [];
    if (appHasCode) {
      if (app?.id) {
        if (!app.is_template) {
          codePublishActions.push({
            content: (
              <MenuLabelItem
                label="Test latest version"
                iconProps={{ icon: PlayIcon, iconSize: 18 }}
                className=""
              ></MenuLabelItem>
            ),
            onClick: onTestYourAppActionClick,
            hasReactivationDelay: true,
          });

          codePublishActions.push({
            content: (
              <div>
                <MenuLabelItem
                  label="Publish latest version to production"
                  iconProps={{ icon: RocketIcon, iconSize: 18 }}
                  className={''}
                ></MenuLabelItem>
              </div>
            ),
            onClick: () => onPublishClick(),
          });
        } else {
          codePublishActions.push({
            content: (
              <div>
                <MenuLabelItem
                  label="Convert to app and test"
                  iconProps={{ icon: PlayIcon, iconSize: 18 }}
                  className={''}
                ></MenuLabelItem>
              </div>
            ),
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick: () => createTestAppForTemplate(app.id),
          });

          codePublishActions.push({
            content: (
              <div>
                <MenuLabelItem
                  label="Publish template"
                  iconProps={{ icon: UploadCouldLineIcon, iconSize: 18 }}
                  className={''}
                ></MenuLabelItem>
              </div>
            ),
            onClick: () => onPublishClick(),
          });
        }

        if (shouldDisplayHintToSuggestSmallFeatures()) {
          codePublishActions.push({
            content: (
              <div>
                <MenuLabelItem
                  label="Suggest features"
                  iconProps={{ icon: LightbulbFlashLineIcon, iconSize: 18 }}
                  className="suggest-small-features-button"
                ></MenuLabelItem>
              </div>
            ),
            onClick: () => suggestSmallFeatures(onSuccess, app, emitResponseReceivedEvent),
            disabled: !userPermissions?.isUserAllowedToPrompt,
          });
        }

        if (!app.is_template) {
          codePublishActions.push({
            content: (
              <div>
                <MenuLabelItem
                  label="Convert into template"
                  iconProps={{ icon: GroupIcon, iconSize: 18 }}
                  className={''}
                ></MenuLabelItem>
              </div>
            ),
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick: () => makeCommunityTemplateFromApp(app.id),
          });
        }
        if (isLazyEmployee) {
          codePublishActions.push({
            content: (
              <div>
                <MenuLabelItem
                  label={app.is_template ? 'Duplicate template' : 'Duplicate app'}
                  iconProps={{ icon: DuplicateAppIcon, iconSize: 18 }}
                  className={''}
                ></MenuLabelItem>
              </div>
            ),
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick: () => copyApp(app.id, !!app.is_template),
          });
        }
      }
    }
    if (!userPermissions?.isUserAllowedToPrompt) {
      codePublishActions.push({
        content: (
          <div>
            <MenuLabelItem
              labelElement={
                userPermissions?.hrsToMorePrompts ? (
                  <span>
                    Daily prompt limit reached.
                    {isMobileDevice() && <br />}
                    Come back in {userPermissions?.hrsToMorePrompts} hours or upgrade.
                  </span>
                ) : (
                  <span>Upgrade to get more prompts</span>
                )
              }
              iconProps={{ icon: FlashlightIcon, iconSize: 18 }}
              className=""
            ></MenuLabelItem>
          </div>
        ),

        onClick: () => upgradeToProButtonHandler(),
        className: classNames('bg-purple-100 hover:!bg-purple-200 !text-purple-500 rounded'),
      });
    }
    return codePublishActions;
  };

  // eslint-disable-next-line max-lines-per-function, max-statements
  const getAvailableSidebarActionsList = (
    app: LazyApp | null,
    onSuccess: () => Promise<void>
  ): RichPromptAction[] => {
    const codePublishActions: RichPromptAction[] = [];

    const setMoveAppToOrgModalOpen = (app: LazyApp | null) => {
      useChatStore.setState({ showMoveAppToOrgModalForApp: app });
    };

    const setMoveAppToFolderModalOpen = (app: LazyApp | null) => {
      useChatStore.setState({ showMoveAppToFolderModalForApp: app });
    };

    if (app?.id) {
      codePublishActions.push({
        content: (
          <div>
            <MenuLabelItem
              label={'Copy link'}
              iconProps={{ icon: LinkIcon, iconSize: 18 }}
              className={''}
            ></MenuLabelItem>
          </div>
        ),
        onClick: () => {
          copyToClipboard(`${window.location.host}/apps/${app.id}/build`);
          toast.success('Link copied successfully.');
        },
      });

      codePublishActions.push({
        content: (
          <div>
            <MenuLabelItem
              label={app.is_template ? 'Duplicate template' : 'Duplicate app'}
              iconProps={{ icon: DuplicateAppIcon, iconSize: 18 }}
              className={''}
            ></MenuLabelItem>
          </div>
        ),
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick: () => copyApp(app.id, !!app.is_template),
      });

      if (app.archive_date === null) {
        codePublishActions.push({
          content: (
            <div>
              <MenuLabelItem
                label={app.is_template ? 'Archive template' : 'Archive app'}
                iconProps={{ icon: ArchiveIcon, iconSize: 18 }}
                className={''}
              ></MenuLabelItem>
            </div>
          ),
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick: () => archiveApp(app.id, onSuccess),
        });
      } else {
        codePublishActions.push({
          content: (
            <div>
              <MenuLabelItem
                label={app.is_template ? 'Unarchive template' : 'Unarchive app'}
                iconProps={{ icon: UnarchiveIcon, iconSize: 18 }}
                className={''}
              ></MenuLabelItem>
            </div>
          ),
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick: () => unarchiveApp(app.id, onSuccess),
        });
      }

      if (!app.org_id && userOrganizationRoleAssignments.length > 0) {
        codePublishActions.push({
          content: (
            <div data-testid={BUILDER_VIEW_MOVE_APP_TO_TEAM_BUTTON}>
              <MenuLabelItem
                label={app.is_template ? 'Move template to team' : 'Move app to team'}
                iconProps={{ icon: HotelLineIcon, iconSize: 18 }}
                className={''}
              ></MenuLabelItem>
            </div>
          ),
          onClick: () => setMoveAppToOrgModalOpen(app),
        });
      }

      if (appFolders.length > 0) {
        codePublishActions.push({
          content: (
            <div data-testid={BUILDER_VIEW_MOVE_APP_TO_FOLDER_BUTTON}>
              <MenuLabelItem
                label={app.is_template ? 'Move template to folder' : 'Move app to folder'}
                iconProps={{ icon: FolderIcon, iconSize: 18 }}
                className={''}
              ></MenuLabelItem>
            </div>
          ),
          onClick: () => setMoveAppToFolderModalOpen(app),
        });
      }
    }
    return codePublishActions;
  };

  return {
    getAvailableChatBarActionsList,
    getAvailableSidebarActionsList,
  };
};
