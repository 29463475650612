import React, { Ref, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import {
  createAppVersion,
  getApp,
  getAppSession,
  getLatestPublishedVersion,
  revertApp,
  publishAppAsTemplate,
  updateBuilderSessionStateStatus,
  getBuilderSessionPreviousMessages,
} from '../api/BuilderApi';
import { createOrGetInstanceForApp } from '../api/ClientApi';
import { FrontendEvents } from '../api/StatsApi';
import { TEST_ID_SIDEBAR_BUTTON, TabBarItemsIndex } from '../constants';

import {
  AppLogic,
  ChatSessionBuilder,
  LazyAppWithMinimalAppVersionWithSessionStateId,
  BuilderSessionStateRequestStatus,
  ContentBuilderMessageGroup,
  File,
  FileFormat,
  ContentBuilderMessageMetadata,
} from '../api/generated/index';
import { useOrganizationStore } from '../store/organization';
import Chat from './chat/Chat';
import AppInfo from './AppInfo';

import { MoveAppToOrgModal } from './MoveAppToOrgModal';
import { MoveAppToFolderModal } from './MoveAppToFolderModal';
import { OrganizationModal } from './OrganizationModal';
import { extractMessagesFromBuilderSession } from '../api/transform';
import SideBarLineIcon from 'remixicon-react/SideBarLineIcon';
import { v4 as uuidv4 } from 'uuid';
import TabBar, { TabBarItem } from './base/TabBar';
import SecretsManagerTab from './SecretsManagerTab';
import CodeLineIcon from 'remixicon-react/CodeLineIcon';
import PlayIcon from 'remixicon-react/PlayFillIcon';
import CodeListing, { ExtendedFile } from './CodeListing';
import AuthNavbar from '../auth/AuthNavbar';
import MyAppsSidebarWithFolders from './MyAppsSidebarWithFolders';
import PublishAppModal from './PublishAppModal';
import PublishTemplateModal from './PublishTemplateModal';
import classNames from 'classnames';
import { getVH, getVW, isMobileDevice } from '../utils/deviceDimensions';
import { getLinkToTemplate, hasElapsed } from '../utils';
import MessageListRenderer from './chat/MessageListRenderer';
import { useAppStore } from '../store/app';
import { useAuthStore } from '../store/auth';
import LazyDarkSlothIcon from '../icons/LazyDarkSlothIcon';
import KeyLineIcon from 'remixicon-react/KeyLineIcon';
import * as dataTestidConstants from '../constants';
import {
  ActionToRunAVersionOfTheApp,
  ActionForParentWindow,
  ErrorTracebackFixRequest,
  Message,
  MessagePublishInfo,
  MessageSourceType,
  TextMessageContent,
} from '../types';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import { getDateFormatted } from './chat/MessageTimestamp';
import RevertMessageButton from './chat/RevertMessageButton';
import { Loader } from './base/Loader';
import ActionButton from './base/ActionButton';
import { AbilitiesAppIframe } from './base/AbilitiesAppIframe';
import { UpgradeToProNotice } from './UpgradeToProNotice';
import { useChatStore } from '../store/chat';
import { useChatAppActionsList } from './chatAppActionsListHook';
import {
  updateAppLogicManually,
  submitUserPromptWithoutWaiting,
  submitUserPromptForSecretUpdate,
  submitStarterPrompt,
  updateVSCodeFilesManually,
  updateFileManually,
} from './promptSubmissionUtils';
import {
  DEFAULT_APP_FILES,
  extractFilesFromContentBuilderMessage,
  extractLastMessageWithCode,
  extractAppLogic,
} from './extractionUtils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppLoadingTest from './AppLoadingTest';
import useRerenderOnResize from '../utils/useRerenderOnResize';
import VSCodeEditorPOC from './VSCodeEditor/VSCodePOC';
import { formatPromptFromTryToFixItFromString } from './chat/MessageGroup';
import { useEventEmitter } from './eventEmitterHook';
import { useTabStore } from '../store/tab';
import WebSocketManager from './VSCodeEditor/vsCodeWebSocketManager';

const DEFAULT_BUILDER_CHAT_PAGINATION_SIZE = 10;

interface AppEditorProps {
  appId: string | null;
}

interface TabBarItemWithPane extends TabBarItem {
  pane?: React.ReactNode;
  // If set, keep the contents in the DOM but hidden when the tab is not selected.
  preserve?: boolean;
}

// Mapping from TabBarItemsIndex to FrontendEvents
const TabBarItemsIndexToEventMapping: Record<TabBarItemsIndex, FrontendEvents> = {
  [TabBarItemsIndex.APP_CHAT]: FrontendEvents.USER_SWITCHED_TO_CHAT_TAB,
  [TabBarItemsIndex.APP_CODE]: FrontendEvents.USER_SWITCHED_TO_CODE_TAB,
  [TabBarItemsIndex.APP_ENV_SECRETS]: FrontendEvents.USER_SWITCHED_TO_ENV_SECRETS_TAB,
  [TabBarItemsIndex.APP_TEST]: FrontendEvents.USER_SWITCHED_TO_TEST_TAB,
  [TabBarItemsIndex.APP_VSCODE]: FrontendEvents.USER_SWITCHED_TO_VSCODE_TAB,
};

// eslint-disable-next-line max-lines-per-function,max-statements,complexity
const AppEditor = ({ appId }: AppEditorProps) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [appTestLoading, setAppTestLoading] = useState<boolean>(false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [isPublishSelectedVersion, setIsPublishSelectedVersion] = useState<boolean>(false);
  const [app, setApp] = useState<LazyAppWithMinimalAppVersionWithSessionStateId | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [appLogic, setApplogic] = useState<AppLogic | null>(null);
  const [isLastAppVersionPublished, setIsLastAppVersionPublished] = useState<boolean>(false);
  const [activeBuilderSessionStateId, setActiveBuilderSessionStateId] = useState<string | null>(
    null
  );
  const [loadedPreviousMessages, setLoadedPreviousMessages] = useState<Message[]>([]);
  const [isAllPreviousMessagesLoaded, setIsAllPreviousMessagesLoaded] = useState(true);
  const desktopVScodeIFrameRef = useRef<HTMLIFrameElement>(null);
  const mobileVScodeIFrameRef = useRef<HTMLIFrameElement>(null);
  const { userIsAuthenticated, userDetails, userPermissions } = useAuthStore();
  const { setAvailableActions, prependMessages, showAbilitiesIframe } = useChatStore();
  const { getAvailableChatBarActionsList } = useChatAppActionsList();
  const [userSessionStateIdWithPublishedVersion, setUserSessionStateIdWithPublishedVersion] =
    useState<MessagePublishInfo[]>([]);

  const [timestampOfOldestLoadedMessage, setTimestampOfOldestLoadedMessage] = useState<Date | null>(
    null
  );

  const [isVSCodeAllowed, setIsVSCodeAllowed] = useState<boolean>(false);

  const {
    loadUserOrganizationRoleAssignments,
    loadRoles,
    isLoading: isOrganizationLoading,
  } = useOrganizationStore();

  const getDefaultTabIndex = () =>
    isMobileDevice()
      ? TabBarItemsIndex.APP_CHAT
      : app?.is_template
      ? TabBarItemsIndex.APP_CODE
      : TabBarItemsIndex.APP_TEST;

  const [files, setFiles] = useState<File[]>(DEFAULT_APP_FILES);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [contentBuilderMessageMetadata, setContentBuilderMessageMetadata] = useState<
    ContentBuilderMessageMetadata | undefined
  >();
  const [modifiedFiles, setModifiedFiles] = useState<File[]>([]);
  const [myAppsSidebarOpen, setMyAppsSidebarOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(getDefaultTabIndex());

  const [lastSelectedItemIndex, setLastSelectedItemIndex] = useState<
    TabBarItemsIndex | undefined
  >();
  const [creatingVersion, setCreatingVersion] = useState(false);
  const [appLastPublishedAt, setAppLastPublishedAt] = useState<Date | null>(null);
  const [appLastPublishedUrl, setAppLastPublishedUrl] = useState<string | null>(null);

  const [isMobileView, setIsMobileView] = useState(false);
  const [viewedBuilderSessionStateId, setViewedBuilderSessionStateId] = useState<string>('');
  const [openPublishModalForBuilderSessionStateId, setOpenPublishModalForBuilderSessionStateId] =
    useState<string>('');
  const [viewedVersionDate, setViewedVersionDate] = useState<string>('');
  const builderTestTabRef: Ref<HTMLIFrameElement> = useRef<HTMLIFrameElement>(null);
  const abilitiesIframeRef = useRef<{ getIframe: () => HTMLIFrameElement | null }>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [appHasCode, setAppHasCode] = useState<boolean>(false);
  const [appName, setAppName] = useState<string>('Untitled app');
  const [queryParamPrompt, setQueryParamPrompt] = useState<string | null>('');
  const [vsCodeWebSocket, setVSCodeWebSocket] = useState<WebSocketManager>();
  const isVSCodeLoaded = useTabStore((state) => state.isVSCodeLoaded);
  const { emitEvent } = useEventEmitter();

  useRerenderOnResize();

  function emitTabSwitchEventIfNeeded(indexSwitchedTo: number) {
    if (indexSwitchedTo !== selectedItemIndex && userDetails && appId) {
      emitEvent(TabBarItemsIndexToEventMapping[indexSwitchedTo] as FrontendEvents, appId);
    }
  }

  const isFilesHasCode = (files: File[]): boolean => {
    return (
      files.length > 0 &&
      files.some((file) => file.content.length > 0 && file.format === FileFormat.PYTHON)
    );
  };

  const getHeaderHeight = (): number => {
    return 49 + (useChatStore.getState().isViewingVersion ? 44 : 0);
  };

  const getUserSessionStateIdWithPublishedState = (
    app: LazyAppWithMinimalAppVersionWithSessionStateId
  ): MessagePublishInfo[] => {
    const userSessionStateIdWithPublishedVersion: MessagePublishInfo[] = [];
    app.app_versions?.forEach((appVersion) => {
      appVersion.artifacts?.content.builder_session_state_id &&
        appVersion.publish_date &&
        userSessionStateIdWithPublishedVersion.push({
          builderSessionStateId: appVersion.artifacts.content.builder_session_state_id,
          appVersionPublishDate: new Date(appVersion.publish_date),
          lastAppVersionPublished: Boolean(appVersion.published),
        });
    });
    userSessionStateIdWithPublishedVersion.sort(
      (a, b) => b.appVersionPublishDate.getTime() - a.appVersionPublishDate.getTime()
    );
    let previousPublishedMessageState = false;
    userSessionStateIdWithPublishedVersion.map((c) => {
      if (previousPublishedMessageState) {
        c.lastAppVersionPublished = false;
      }
      if (!previousPublishedMessageState && c.lastAppVersionPublished) {
        previousPublishedMessageState = true;
      }
      return c;
    });
    return userSessionStateIdWithPublishedVersion;
  };

  const getAppVersionSessionContentToView = (
    id: string
  ): {
    currentViewedAppVersion: ContentBuilderMessageGroup | undefined;
    previousAppVersion: ContentBuilderMessageGroup | undefined;
  } => {
    const currentViewedAppVersionIndex = useChatStore
      .getState()
      .appChatSession?.content_builder.findIndex(
        // eslint-disable-next-line camelcase
        (content_builder) => content_builder.content.builder_session_state_id === id
      );
    if (currentViewedAppVersionIndex) {
      return {
        currentViewedAppVersion:
          useChatStore.getState().appChatSession?.content_builder[currentViewedAppVersionIndex],
        previousAppVersion:
          currentViewedAppVersionIndex > 2
            ? useChatStore.getState().appChatSession?.content_builder[
                currentViewedAppVersionIndex - 2
              ]
            : // case of copied apps where the first prompt has files.
            useChatStore.getState().appChatSession?.content_builder[
                currentViewedAppVersionIndex - 1
              ].content.files?.length
            ? useChatStore.getState().appChatSession?.content_builder[
                currentViewedAppVersionIndex - 1
              ]
            : undefined,
      };
    }
    return {
      currentViewedAppVersion: undefined,
      previousAppVersion: undefined,
    };
  };

  const setAppSessionContent = (
    currentAppVersionBuilderSession?: ChatSessionBuilder,
    previousAppVersionBuilderSession?: ChatSessionBuilder
  ) => {
    if (currentAppVersionBuilderSession) {
      const viewedCodeSession = extractLastMessageWithCode(currentAppVersionBuilderSession);
      if (viewedCodeSession && viewedCodeSession.builder_session_state_id) {
        useChatStore.setState({
          currentBuilderSessionStateId: viewedCodeSession?.builder_session_state_id,
        });
      }

      setApplogic(extractAppLogic(currentAppVersionBuilderSession));

      if (currentAppVersionBuilderSession.content_builder.length > 0) {
        const lastContentBuilderMessage =
          currentAppVersionBuilderSession.content_builder[
            currentAppVersionBuilderSession.content_builder.length - 1
          ];

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setContentBuilderMessageMetadata(lastContentBuilderMessage.content.metadata || undefined);
      }

      const originalCodeSession = previousAppVersionBuilderSession
        ? extractLastMessageWithCode(previousAppVersionBuilderSession)
        : undefined;
      setFiles(extractFilesFromContentBuilderMessage(viewedCodeSession));
      if (
        originalCodeSession &&
        originalCodeSession.files &&
        isFilesHasCode(originalCodeSession.files)
      ) {
        setFiles(extractFilesFromContentBuilderMessage(originalCodeSession));
        setModifiedFiles(extractFilesFromContentBuilderMessage(viewedCodeSession));
      } else {
        setModifiedFiles([]);
      }
    }
  };

  const setViewedMessageId = (id: string, lastBuilderSessionStateMessageId?: string): void => {
    if (lastBuilderSessionStateMessageId !== id) {
      setSelectedItemIndex(TabBarItemsIndex.APP_CODE);
      useChatStore.setState({
        isViewingVersion: true,
      });
      useChatStore.setState({ viewedMessageId: id });
    } else {
      useChatStore.setState({
        isViewingVersion: false,
      });
      useChatStore.setState({ viewedMessageId: '' });
    }
  };

  const viewAppVersion = (id: string) => {
    const { currentViewedAppVersion, previousAppVersion } = getAppVersionSessionContentToView(id);
    const currentContentBuilder = useChatStore.getState().appChatSession?.content_builder;
    if (currentViewedAppVersion && currentContentBuilder && currentContentBuilder.length) {
      setViewedBuilderSessionStateId(id);
      const lastBuilderSessionStateMessage =
        currentContentBuilder[currentContentBuilder.length - 1];

      setViewedMessageId(id, lastBuilderSessionStateMessage.content.builder_session_state_id);
      if (currentViewedAppVersion.sent_at) {
        setViewedVersionDate(
          getDateFormatted(
            currentViewedAppVersion.sent_at ? new Date(currentViewedAppVersion.sent_at) : new Date()
          )
        );
      }
      const currentViewedAppVersionBuilderSession: ChatSessionBuilder = {
        app_skeleton_name: useChatStore.getState().appChatSession?.app_skeleton_name,
        content_builder: [currentViewedAppVersion],
      };

      const previousAppVersionBuilderSession: ChatSessionBuilder | undefined = previousAppVersion
        ? {
            app_skeleton_name: useChatStore.getState().appChatSession?.app_skeleton_name,
            content_builder: [previousAppVersion],
          }
        : undefined;
      setAppSessionContent(currentViewedAppVersionBuilderSession, previousAppVersionBuilderSession);
    } else {
      setAppSessionContent(useChatStore.getState().appChatSession);
    }
  };

  const testVersionFunction = (id: string) => {
    setSelectedItemIndex(TabBarItemsIndex.APP_TEST);
    setActiveBuilderSessionStateId(id);
    const messageForAppRunWindow: ActionToRunAVersionOfTheApp = {
      builderSessionStateId: id,
    };
    builderTestTabRef.current?.contentWindow?.postMessage(messageForAppRunWindow, '*');
  };

  const getLastAppSessionStateBuilderId = (chatSessionBuilder: ChatSessionBuilder): string => {
    if (chatSessionBuilder && chatSessionBuilder?.content_builder) {
      return (
        chatSessionBuilder?.content_builder.slice(-1)[0].content.builder_session_state_id || ''
      );
    }
    return '';
  };

  const setViewedAppVersionToLatestVersion = (chatSessionBuilder?: ChatSessionBuilder) => {
    if (chatSessionBuilder) {
      const lastAppSessionStateId = getLastAppSessionStateBuilderId(chatSessionBuilder);
      if (lastAppSessionStateId) {
        viewAppVersion(lastAppSessionStateId);
      } else {
        setAppSessionContent(chatSessionBuilder);
      }
    }
  };

  const closeAppViewing = () => {
    useChatStore.setState({ isViewingVersion: false });
    setViewedAppVersionToLatestVersion(useChatStore.getState().appChatSession);
    setSelectedItemIndex(TabBarItemsIndex.APP_CODE);
  };

  const isAppLastVersionPublished = (messages: Message[]): boolean => {
    const tempMessages = [...messages];
    const lastMessageFromSystem = tempMessages
      .reverse()
      .find((message) => message.source.type === MessageSourceType.System);
    if (lastMessageFromSystem && lastMessageFromSystem.lastAppVersionPublished) {
      return true;
    }
    return false;
  };

  const createDeployLinkToApp = (app: LazyAppWithMinimalAppVersionWithSessionStateId) =>
    `${window.location.origin.toString()}/${app.readable_name || app.id}`;

  async function stopRequest() {
    if (appId) {
      const builderSessionState = await getAppSession(appId, DEFAULT_BUILDER_CHAT_PAGINATION_SIZE);
      if (
        builderSessionState &&
        builderSessionState.request_status === BuilderSessionStateRequestStatus.INFLIGHT
      ) {
        await updateBuilderSessionStateStatus(
          builderSessionState.id,
          BuilderSessionStateRequestStatus.USER_CANCELLED
        );
      }
    }
  }

  const resetLoadedPreviousMessages = () => {
    setLoadedPreviousMessages([]);
    setTimestampOfOldestLoadedMessage(null);
    setIsAllPreviousMessagesLoaded(true);
  };

  const connectVSCodeWebSocket = () => {
    if (vsCodeWebSocket) {
      return;
    }
    const tabId = uuidv4();
    if (!useTabStore.getState().id) {
      useTabStore.setState({ id: tabId });
    }
    if (
      process.env.REACT_APP_ENABLE_VSCODE === 'true' &&
      useAuthStore.getState().userIsAuthenticated
    ) {
      const vscodeWebsocket = WebSocketManager.getInstance();
      setVSCodeWebSocket(vscodeWebsocket);
    }
  };

  const loadPreviousMessages = async (pageSize = DEFAULT_BUILDER_CHAT_PAGINATION_SIZE) => {
    if (app?.id && timestampOfOldestLoadedMessage) {
      const newlyLoadedMessages = await getBuilderSessionPreviousMessages(
        app?.id,
        timestampOfOldestLoadedMessage.toISOString(),
        pageSize
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (newlyLoadedMessages.length === 0) {
        setIsAllPreviousMessagesLoaded(true);
        return;
      }

      const existingAppChatSession = useChatStore.getState().appChatSession;

      const newlyLoadedMessagesExtracted = extractMessagesFromBuilderSession(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        { ...existingAppChatSession, content_builder: newlyLoadedMessages },
        userSessionStateIdWithPublishedVersion
      );

      const contentBuilder = [
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ...newlyLoadedMessages,
        ...(existingAppChatSession?.content_builder || []),
      ];
      const updatedAppChatSession = { ...existingAppChatSession, content_builder: contentBuilder };
      useChatStore.setState({ appChatSession: updatedAppChatSession });
      prependMessages(newlyLoadedMessagesExtracted);
      setLoadedPreviousMessages([...newlyLoadedMessagesExtracted, ...loadedPreviousMessages]);
      setTimestampOfOldestLoadedMessage(newlyLoadedMessagesExtracted[0].sentAt || null);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      setIsAllPreviousMessagesLoaded(newlyLoadedMessages.length < pageSize);
    }
  };

  // eslint-disable-next-line max-lines-per-function, max-statements
  async function fetchChatSessionAndProcess(
    app: LazyAppWithMinimalAppVersionWithSessionStateId,
    skipSessionPolling
  ) {
    let sessionPollingAttemptNumber = 0;
    while (true && !useChatStore.getState().isViewingVersion) {
      sessionPollingAttemptNumber += 1;
      const builderSessionState = await getAppSession(app.id, DEFAULT_BUILDER_CHAT_PAGINATION_SIZE);
      const chatSession = builderSessionState.state;

      setIsAllPreviousMessagesLoaded(
        chatSession.content_builder.length < DEFAULT_BUILDER_CHAT_PAGINATION_SIZE
      );

      useChatStore.setState({ appChatSession: chatSession });

      const userSessionStateIdWithPublishedVersion = getUserSessionStateIdWithPublishedState(app);
      setUserSessionStateIdWithPublishedVersion(userSessionStateIdWithPublishedVersion);

      const messages = extractMessagesFromBuilderSession(
        chatSession,
        userSessionStateIdWithPublishedVersion
      );

      setIsLastAppVersionPublished(isAppLastVersionPublished(messages));

      if (builderSessionState.request_status === BuilderSessionStateRequestStatus.ERROR_OCCURRED) {
        const builderRequestErrorMessage =
          'We ran into a slight hiccup, please retry. ' +
          'Make your request smaller if retrying does not work.';
        const lastMessage = messages.slice(-1)[0];
        const allButTheLastMessage = messages.slice(0, -1);
        useChatStore.setState(() => ({
          error: builderRequestErrorMessage,
          messages: allButTheLastMessage,
          currentUserPrompt: (lastMessage.content as TextMessageContent).text,
        }));
        setTimestampOfOldestLoadedMessage(allButTheLastMessage[0].sentAt || null);
      } else {
        useChatStore.setState(() => ({ messages }));
        useChatStore.setState({
          currentUserPrompt: '',
        });
        setTimestampOfOldestLoadedMessage(messages[0].sentAt || null);
      }

      setViewedAppVersionToLatestVersion(chatSession);

      const userClickedStop = useChatStore.getState().userClickedStop;
      if (
        userClickedStop ||
        builderSessionState.request_status !== BuilderSessionStateRequestStatus.INFLIGHT
      ) {
        useChatStore.setState(() => ({ userInputLoading: false }));
        if (!userClickedStop && sessionPollingAttemptNumber > 1) {
          emitEvent(FrontendEvents.USER_PROMPT_RECEIVED_RESPONSE, appId || undefined, undefined, {
            correlation_id:
              chatSession.content_builder.slice(-1)[0]?.content?.metadata?.correlation_id,
            session_polling_attempt_number: sessionPollingAttemptNumber,
          });
        }
        break;
      } else if (
        builderSessionState.request_status === BuilderSessionStateRequestStatus.INFLIGHT &&
        hasElapsed(chatSession.content_builder.slice(-1)[0].sent_at, 5)
      ) {
        // auto stop requests if a request has been in flight for 5 minutes
        await stopRequest();
        break;
      } else {
        useChatStore.setState(() => ({ userInputLoading: true }));
        setIsLoading(false);
      }

      if (skipSessionPolling) {
        break;
      }
      // TODO: Use better approach than polling. Maybe websocket for session updates?
      // eslint-disable-next-line compat/compat
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
  }

  const emitUserPromptResponseReceivedEvent = (correlationId: string) => {
    emitEvent(FrontendEvents.USER_PROMPT_RECEIVED_RESPONSE, appId || undefined, undefined, {
      correlation_id: correlationId,
    });
  };

  async function loadData(skipSessionPolling = true): Promise<void> {
    connectVSCodeWebSocket();
    if (appId) {
      setIsLoading(true);
      const loadedApp = await getApp(appId);
      useAppStore.setState({ isTemplate: loadedApp.is_template });
      setApp(loadedApp);

      const lastPublishedVersion = await getLatestPublishedVersion(appId);
      if (lastPublishedVersion !== null) {
        setAppLastPublishedAt(new Date(Date.parse(lastPublishedVersion.publish_date || '')));
        setAppLastPublishedUrl(
          loadedApp.is_template ? getLinkToTemplate(loadedApp) : createDeployLinkToApp(loadedApp)
        );
      } else {
        setAppLastPublishedUrl(null);
        setAppLastPublishedAt(null);
      }
      await fetchChatSessionAndProcess(loadedApp, skipSessionPolling);
      setIsLoading(false);
    }
  }

  const updateTestTabContent = () => {
    if (builderTestTabRef.current) {
      const currentSrc = builderTestTabRef.current.src;
      builderTestTabRef.current.src = currentSrc;
    }
  };

  const preparePreviewLink = async (published: boolean) => {
    if (app !== null && appHasCode) {
      setCreatingVersion(true);

      try {
        if (published) {
          // Publish the current builder state.
          await createAppVersion(app, true, openPublishModalForBuilderSessionStateId);
        } else {
          setAppTestLoading(true);
          // eslint-disable-next-line no-void, promise/always-return
          const instance = await createOrGetInstanceForApp(app.id, true);
          if (instance.state_info?.state) {
            setActiveBuilderSessionStateId(
              instance?.version?.artifacts?.content?.builder_session_state_id || null
            );
          }
          setAppTestLoading(false);
          updateTestTabContent();
        }
      } finally {
        if (published) {
          await loadData(); // Update the published app link and any other updated fields.
        }
        setCreatingVersion(false);
        if (openPublishModalForBuilderSessionStateId !== '') {
          setPublishModalOpen(false);
          setOpenPublishModalForBuilderSessionStateId('');
        }
      }
    }
  };

  const publishTemplate = async (name: string, description: string, videoUrl: string) => {
    if (app !== null && appHasCode) {
      await publishAppAsTemplate(
        app.id,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        name || (app.name as string),
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        description || (app.description as string),
        videoUrl
      );
      await loadData();
    }
  };

  const getTestTabContent = () => {
    if (appHasCode && app?.id && !app.is_template) {
      if (!userPermissions?.isUserAllowedToRunApp) {
        return <UpgradeToProNotice />;
      }

      return (
        <iframe
          ref={builderTestTabRef}
          name="lazy-test-app"
          className={classNames('flex h-full w-full', {
            'opacity-20 pointer-events-none': appTestLoading,
          })}
          src={appId ? `${window.location.origin}/test_instance/${appId}` : ''}
          data-hj-allow-iframe
        ></iframe>
      );
    }
    return <AppLoadingTest></AppLoadingTest>;
  };

  const previewApp = (published: boolean) => {
    if (!creatingVersion) {
      setCreatingVersion(true);

      preparePreviewLink(published).catch(() => {
        toast.error('An unknown error occurred, please try again');
      });
    }
  };

  const revertAppChange = async (id: string) => {
    if (useChatStore.getState().isViewingVersion) {
      closeAppViewing();
    }
    if (appId) {
      resetLoadedPreviousMessages();
      await revertApp(appId, id);
      await loadData();
    }
  };

  // Updates the condition that unblocks preview / publish apps: if the app has code.
  useEffect(() => {
    setAppHasCode(isFilesHasCode(files));
  }, [files]);

  useEffect(() => {
    setIsPublishSelectedVersion(!!openPublishModalForBuilderSessionStateId);
    setPublishModalOpen(!!openPublishModalForBuilderSessionStateId);
  }, [openPublishModalForBuilderSessionStateId]);

  useEffect(() => {
    if (appId) {
      resetLoadedPreviousMessages();
      loadData(false)
        .then(() => {
          setIsLoading(false);
          return null;
        })
        .catch(() => toast.error('An unknown error occurred. Please try again'));
      if (userIsAuthenticated) {
        loadUserOrganizationRoleAssignments().catch(() =>
          toast.error('An unknown error occurred. Please try again')
        );
        loadRoles().catch(() => toast.error('An unknown error occurred. Please try again'));
      }
    }
    setCreatingVersion(false);
    useChatStore.setState(() => ({
      revertFunction: revertAppChange,
      viewVersionFunction: viewAppVersion,
      testVersionFunction,
    }));
  }, [appId]);

  const getSecretsTabContent = () => {
    if (selectedItemIndex !== TabBarItemsIndex.APP_ENV_SECRETS) {
      return <div></div>;
    }
    return (
      <SecretsManagerTab
        appId={appId}
        submitUserPromptForSecretUpdate={submitUserPromptForSecretUpdate(loadData, app)}
      />
    );
  };

  const tabBarItems: TabBarItemWithPane[] = [
    {
      contents: <></>,
      pane: <></>,
      props: { extraClasses: ['hidden'] },
    },
    {
      contents: (
        <span>
          <PlayIcon className="mr-2 inline-block align-text-bottom" size="1.2em" />
          Test
        </span>
      ),
      pane: getTestTabContent(),
      preserve: true,
      disabled: !useAuthStore.getState().userIsAuthenticated,
      dataTestid: dataTestidConstants.BUILDER_VIEW_TEST_TAB_BUTTON,
      props:
        app?.is_template || useChatStore.getState().isViewingVersion
          ? { extraClasses: ['hidden'] }
          : { extraClasses: [] },
    },
    {
      contents: (
        <span>
          <CodeLineIcon className="mr-2 inline-block align-text-bottom" size="1.2em" />
          Code
        </span>
      ),
      pane: (
        <div className="w-full">
          {process.env.REACT_APP_ENABLE_VSCODE === 'false' ? (
            <CodeListing
              files={files as ExtendedFile[]}
              modifiedFiles={modifiedFiles as ExtendedFile[]}
              updateFile={updateFileManually(
                modifiedFiles && modifiedFiles.length ? modifiedFiles : files,
                loadData,
                app
              )}
              grabFocus={() => {
                /* do nothing */
              }}
            />
          ) : (
            <VSCodeEditorPOC
              files={files as ExtendedFile[]}
              modifiedFiles={modifiedFiles as ExtendedFile[]}
              updateFile={updateVSCodeFilesManually(
                modifiedFiles.length ? modifiedFiles : files,
                loadData,
                app
              )}
              appName={app?.name}
              vsCodeWebSocket={vsCodeWebSocket}
              grabFocus={() => {
                /* do nothing */
              }}
              appId={app?.id || ''}
            />
          )}
        </div>
      ),
      dataTestid: dataTestidConstants.BUILDER_VIEW_CODE_TAB_BUTTON,
      disabled: !useAuthStore.getState().userIsAuthenticated,
    },
    {
      contents: (
        <span>
          <KeyLineIcon className="mr-2 inline-block align-text-bottom" size="1.2em" />
          Env Secrets
        </span>
      ),
      pane: getSecretsTabContent(),
      disabled: !appHasCode || !useAuthStore.getState().userIsAuthenticated,
      dataTestid: dataTestidConstants.BUILDER_VIEW_SECRETS_TAB_BUTTON,
      hidden: useChatStore.getState().isViewingVersion,
      props: !app?.is_template ? { extraClasses: [] } : { extraClasses: ['hidden'] },
    },
  ];

  const shouldFocus = false;

  const mobileTabBarItems: TabBarItemWithPane[] = [
    {
      contents: <LazyDarkSlothIcon className="mr-1 inline-block align-text-bottom" size="1.3em" />,
      pane: (
        <div
          className={classNames('flex overflow-auto no-scrollbar w-full h-full flex-col')}
          ref={chatContainerRef}
          style={{
            maxHeight: getVH() + 100,
          }}
        >
          <MessageListRenderer
            app={app}
            submitStarterPrompt={submitStarterPrompt(
              loadData,
              app,
              emitUserPromptResponseReceivedEvent
            )}
            loadMoreMessages={loadPreviousMessages}
            isAllPreviousMessagesLoaded={isAllPreviousMessagesLoaded}
            onPublishVersion={setOpenPublishModalForBuilderSessionStateId}
            activeBuilderSessionStateId={activeBuilderSessionStateId}
          />
          <div className="flex border-t">
            <Chat
              isAppRun={false}
              shouldFocus={shouldFocus}
              submitUserPrompt={submitUserPromptWithoutWaiting(
                loadData,
                app,
                emitUserPromptResponseReceivedEvent
              )}
              onStopRequest={stopRequest}
            />
          </div>
        </div>
      ),
      dataTestid: dataTestidConstants.BUILDER_VIEW_CHAT_TAB_BUTTON,
      disabled: !useAuthStore.getState().userIsAuthenticated,
    },
    {
      contents: (
        <span>
          <PlayIcon className="mr-2 inline-block align-text-bottom" size="1.2em" />
        </span>
      ),
      pane: getTestTabContent(),
      preserve: true,
      disabled: !useAuthStore.getState().userIsAuthenticated,
      dataTestid: dataTestidConstants.BUILDER_VIEW_TEST_TAB_BUTTON,
      props:
        !useChatStore.getState().isViewingVersion && !app?.is_template
          ? { extraClasses: [] }
          : { extraClasses: ['hidden'] },
    },
    {
      contents: (
        <span className="flex text-[13px]">
          <CodeLineIcon className="mr-1 inline-block align-text-bottom" size="1.3em" />
        </span>
      ),
      pane:
        process.env.REACT_APP_ENABLE_VSCODE === 'false' ? (
          <div className="lg:w-[708px] w-full pl-3 pr-3 pt-4">
            <CodeListing
              files={files as ExtendedFile[]}
              modifiedFiles={modifiedFiles as ExtendedFile[]}
              updateFile={updateFileManually(files, loadData, app)}
              grabFocus={() => {
                /* do nothing */
              }}
            />
          </div>
        ) : (
          <div className="lg:w-[708px] w-full pl-3 pr-3">
            <VSCodeEditorPOC
              files={files as ExtendedFile[]}
              modifiedFiles={modifiedFiles as ExtendedFile[]}
              updateFile={updateVSCodeFilesManually(
                modifiedFiles.length ? modifiedFiles : files,
                loadData,
                app
              )}
              grabFocus={() => {
                /* do nothing */
              }}
              appName={app?.name}
              vsCodeWebSocket={vsCodeWebSocket}
              appId={app?.id || ''}
            />
          </div>
        ),
      dataTestid: dataTestidConstants.BUILDER_VIEW_CODE_TAB_BUTTON,
      disabled: !useAuthStore.getState().userIsAuthenticated,
    },
    {
      contents: (
        <span>
          <KeyLineIcon className="mr-2 inline-block align-text-bottom" size="1.2em" />
        </span>
      ),
      pane: getSecretsTabContent(),
      disabled: !appHasCode || !useAuthStore.getState().userIsAuthenticated,
      dataTestid: dataTestidConstants.BUILDER_VIEW_SECRETS_TAB_BUTTON,
      hidden: useChatStore.getState().isViewingVersion,
      props: !app?.is_template ? { extraClasses: [] } : { extraClasses: ['hidden'] },
    },
  ];

  const onClickTestNewVersion = () => {
    setSelectedItemIndex(TabBarItemsIndex.APP_TEST);
    setActiveBuilderSessionStateId(null);
    const messageForAppRunWindow: ActionToRunAVersionOfTheApp = {
      builderSessionStateId: '', // unset builderSessionStateId means deploy latest version
    };
    builderTestTabRef.current?.contentWindow?.postMessage(messageForAppRunWindow, '*');
  };

  const getAvailableChatBarActionsListForCurrentApp = () =>
    getAvailableChatBarActionsList(
      app,
      appHasCode,
      loadData,
      onClickTestNewVersion,
      () => {
        setIsPublishSelectedVersion(false);
        setPublishModalOpen(true);
      },
      emitUserPromptResponseReceivedEvent
    );

  useEffect(() => {
    setAvailableActions(getAvailableChatBarActionsListForCurrentApp());
  }, [app, contentBuilderMessageMetadata, app, userPermissions, appHasCode, creatingVersion]);

  useEffect(() => {
    if (appHasCode) {
      previewApp(false);
    }
  }, [appHasCode]);

  useEffect(() => {
    mobileTabBarItems.map((mobileTabBarItem, index) =>
      index !== TabBarItemsIndex.APP_ENV_SECRETS
        ? (mobileTabBarItem.disabled = appHasCode)
        : mobileTabBarItem
    );
    tabBarItems.map((tabBarItem, index) =>
      index !== TabBarItemsIndex.APP_ENV_SECRETS ? (tabBarItem.disabled = appHasCode) : tabBarItem
    );
  }, [appHasCode]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(isMobileDevice());
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleGoggleAuthClick = (e: MouseEvent) => {
    const target = e.target as HTMLAnchorElement;

    if (target.tagName === 'A') {
      e.preventDefault();
      window.open(target.href);
    }
  };

  const handleIframeLoad = () => {
    const iframeDocument =
      builderTestTabRef.current?.contentDocument ||
      builderTestTabRef.current?.contentWindow?.document;

    iframeDocument?.addEventListener('click', handleGoggleAuthClick);
  };

  useEffect(() => {
    const iframeDocument =
      builderTestTabRef.current?.contentDocument ||
      builderTestTabRef.current?.contentWindow?.document;

    builderTestTabRef.current?.addEventListener('load', handleIframeLoad);

    return () => {
      builderTestTabRef.current?.removeEventListener('load', handleIframeLoad);
      iframeDocument?.removeEventListener('click', handleGoggleAuthClick);
    };
  }, [selectedItemIndex]);

  // eslint-disable-next-line max-lines-per-function
  useEffect(() => {
    // eslint-disable-next-line max-lines-per-function, max-statements
    const handleMessageFromTestAppIframe = (event: MessageEvent) => {
      if (
        event?.source === builderTestTabRef.current?.contentWindow ||
        (abilitiesIframeRef.current &&
          event?.source === abilitiesIframeRef.current?.getIframe()?.contentWindow)
      ) {
        if ((event?.data as ErrorTracebackFixRequest).tryToFixError) {
          if (!useChatStore.getState().userInputLoading) {
            const errorTracebackPrompt = formatPromptFromTryToFixItFromString(
              (event?.data as ErrorTracebackFixRequest).errorTraceback
            );
            if (app?.id === (event?.data as ErrorTracebackFixRequest).appId) {
              const submitPrompt = submitUserPromptWithoutWaiting(
                loadData,
                app,
                emitUserPromptResponseReceivedEvent
              );
              submitPrompt(errorTracebackPrompt);
              if (isMobileDevice()) {
                setSelectedItemIndex(TabBarItemsIndex.APP_CHAT);
              }
            } else {
              const url = `/apps/${appId || ''}/build?prompt=${encodeURIComponent(
                errorTracebackPrompt
              )}`;
              window.open(url, '_blank');
            }
          }
        } else if ((event?.data as ErrorTracebackFixRequest).missingEnvVarError) {
          setSelectedItemIndex(TabBarItemsIndex.APP_ENV_SECRETS);
        } else if ((event?.data as ActionForParentWindow).openPublishModal) {
          setIsPublishSelectedVersion(false);
          setPublishModalOpen(true);
        } else if ((event?.data as ActionForParentWindow).closeAbilitiesIframe) {
          useChatStore.setState({ showAbilitiesIframe: false });
        } else if ((event?.data as ActionForParentWindow).resetOutdatedTestVersionRunningStatus) {
          setActiveBuilderSessionStateId(null);
        } else if ((event?.data as ActionForParentWindow).submitPrompt !== undefined) {
          const prompt: string = (event?.data as ActionForParentWindow).submitPrompt as string;
          const submitPrompt = submitUserPromptWithoutWaiting(
            loadData,
            app,
            emitUserPromptResponseReceivedEvent
          );
          submitPrompt(prompt);
          if (isMobileDevice()) {
            setSelectedItemIndex(TabBarItemsIndex.APP_CHAT);
          }
          useChatStore.setState({ showAbilitiesIframe: false });
        }
      }
    };

    window.addEventListener('message', handleMessageFromTestAppIframe);

    return () => {
      window.removeEventListener('message', handleMessageFromTestAppIframe);
    };
  }, [builderTestTabRef, app?.id]);

  useEffect(() => {
    if (useChatStore.getState().isViewingVersion) {
      closeAppViewing();
    }
  }, [appId]);

  const onOpenSideBar = () => {
    setMyAppsSidebarOpen(true);
    if (isMobileDevice()) {
      setLastSelectedItemIndex(selectedItemIndex);
      setSelectedItemIndex(TabBarItemsIndex.APP_CHAT);
    }
  };

  const onCloseSideBar = () => {
    setMyAppsSidebarOpen(false);
    if (lastSelectedItemIndex) {
      setSelectedItemIndex(
        lastSelectedItemIndex === TabBarItemsIndex.APP_CODE
          ? isMobileDevice()
            ? TabBarItemsIndex.APP_CHAT
            : TabBarItemsIndex.APP_TEST
          : lastSelectedItemIndex
      );
      setLastSelectedItemIndex(undefined);
    }
  };

  const setTabBarInQsParams = () => {
    searchParams.set('tab', selectedItemIndex.toString());
    setSearchParams(searchParams);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  useEffect(() => {
    if (app) {
      setTabBarInQsParams();
    }
  }, [selectedItemIndex]);

  useEffect(() => {
    updateTestTabContent();
  }, [appLogic?.app_name, appLogic?.summary]);

  useEffect(() => {
    if (app) {
      const tab = searchParams.get('tab');
      if (
        tab &&
        Object.values(TabBarItemsIndex).includes(Number(tab)) &&
        !(!isMobileDevice() && Number(tab) === TabBarItemsIndex.APP_CHAT) &&
        !(app.is_template && Number(tab) === TabBarItemsIndex.APP_TEST)
      ) {
        setSelectedItemIndex(Number(tab));
      } else {
        setSelectedItemIndex(getDefaultTabIndex());
        setTabBarInQsParams();
      }
    }
  }, [app]);

  useEffect(() => {
    if (app?.name) {
      setAppName(app?.name);
    }
  }, [app?.name]);

  useEffect(() => {
    setQueryParamPrompt(
      searchParams.get('prompt') || localStorage.getItem(dataTestidConstants.INITIAL_PROMPT_KEY)
    );
    setMyAppsSidebarOpen(!!searchParams.get('sidebar_open'));
  }, []);

  useEffect(() => {
    if (app) {
      setTimeout(() => {
        if (queryParamPrompt && queryParamPrompt.trim()) {
          useChatStore.setState({ currentUserPrompt: queryParamPrompt });
          const submitPrompt = submitUserPromptWithoutWaiting(
            loadData,
            app,
            emitUserPromptResponseReceivedEvent
          );
          localStorage.setItem(dataTestidConstants.CURRENT_ACTIVE_APP_ID, app.id);
          submitPrompt(queryParamPrompt);
          setQueryParamPrompt('');
          if (localStorage.getItem(dataTestidConstants.INITIAL_PROMPT_KEY)) {
            localStorage.removeItem(dataTestidConstants.INITIAL_PROMPT_KEY);
          }
        }
      }, 500);
    }
  }, [app]);

  useEffect(() => {
    if (app) {
      setUserSessionStateIdWithPublishedVersion(getUserSessionStateIdWithPublishedState(app));
    } else {
      setUserSessionStateIdWithPublishedVersion([]);
    }
  }, [app]);

  useEffect(() => {
    if (!userIsAuthenticated) {
      setSelectedItemIndex(getDefaultTabIndex());
    }
  }, [userIsAuthenticated]);

  // eslint-disable-next-line max-lines-per-function, max-statements
  const createVSCodeIFrame = (tabId: string): void => {
    // eslint-disable-next-line max-lines-per-function, max-statements
    const intervalId = setInterval(() => {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      const isLazyVSCodeNotAvailable: boolean = (window as any).lazyVSCodeNotAvailable;
      // eslint-disable-next-line max-len
      // eslint-disable-next-line max-len, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      const lazyServiceWorkerActivated: boolean = (window as any).lazyServiceWorkerActivated;
      if (!lazyServiceWorkerActivated && !isLazyVSCodeNotAvailable) {
        return;
      }
      if (
        useAuthStore.getState().userIsAuthenticated &&
        process.env.REACT_APP_ENABLE_VSCODE === 'true' &&
        useTabStore.getState().id
      ) {
        const currentVSCodeContainer = isMobileDevice()
          ? mobileVScodeIFrameRef
          : desktopVScodeIFrameRef;
        if (!isLazyVSCodeNotAvailable) {
          const vscodeIFrame: HTMLIFrameElement = document.createElement('iframe');
          vscodeIFrame.id = 'vscode-editor';
          // eslint-disable-next-line max-len
          vscodeIFrame.src = `${window.location.protocol}//${window.location.host}/vscode-intercept/${tabId}/`;
          vscodeIFrame.style.height = `100%`;
          vscodeIFrame.style.width = '100%';
          vscodeIFrame.allow = 'display-capture';
          if (
            currentVSCodeContainer &&
            currentVSCodeContainer.current &&
            !Array.from(currentVSCodeContainer.current.children).some(
              (child) => child.tagName === 'IFRAME' && child.id === 'vscode-editor'
            )
          ) {
            currentVSCodeContainer.current.appendChild(vscodeIFrame);
          }
        } else {
          if (
            currentVSCodeContainer &&
            currentVSCodeContainer.current &&
            !Array.from(currentVSCodeContainer.current.children).some(
              (child) => child.id === 'vscodeNotAvailableWarning'
            )
          ) {
            const vscodeNotAvailableContainer = document.createElement('div');
            vscodeNotAvailableContainer.style.display = 'flex';
            vscodeNotAvailableContainer.style.alignItems = 'center';
            vscodeNotAvailableContainer.style.justifyContent = 'center';
            vscodeNotAvailableContainer.style.height = '100%';
            vscodeNotAvailableContainer.style.width = '100%';

            const vscodeNotAvailable = document.createElement('span');
            vscodeNotAvailable.id = 'vscodeNotAvailableWarning';
            vscodeNotAvailable.textContent = 'VSCode is not available in incognito mode.';
            vscodeNotAvailable.style.fontWeight = 'bold';
            vscodeNotAvailable.style.fontSize = '24px';
            vscodeNotAvailable.style.color = 'red';
            vscodeNotAvailable.style.textAlign = 'center';

            useTabStore.getState().setVSCodeLoaded(true);
            vscodeNotAvailableContainer.appendChild(vscodeNotAvailable);
            currentVSCodeContainer.current.appendChild(vscodeNotAvailableContainer);
          }
        }
      }
      clearInterval(intervalId);
    }, 100);
  };

  useEffect(() => {
    if (!useTabStore.getState().id) {
      // The tab id needs to be set to proceed.
      return;
    }
    createVSCodeIFrame(useTabStore.getState().id);
  }, [desktopVScodeIFrameRef.current, mobileVScodeIFrameRef.current, useTabStore.getState().id]);

  useEffect(() => {
    setIsVSCodeAllowed(
      process.env.REACT_APP_ENABLE_VSCODE === 'true' && useAuthStore.getState().userIsAuthenticated
    );
  }, [process.env.REACT_APP_ENABLE_VSCODE, useAuthStore.getState().userIsAuthenticated]);

  // TODO: @mazenmmb refactor the view here so only one view is displayed
  // and control the elements with css
  return (
    <div
      className={classNames('flex w-full overflow-hidden', {
        'h-screen': !isMobileView,
      })}
    >
      {appId && <OrganizationModal />}
      <MoveAppToOrgModal onSuccess={loadData} />
      <MoveAppToFolderModal onSuccess={loadData} />
      {showAbilitiesIframe && <AbilitiesAppIframe ref={abilitiesIframeRef} />}
      {publishModalOpen &&
        app &&
        (app.is_template ? (
          <PublishTemplateModal
            app={app}
            onHide={() => {
              setPublishModalOpen(false);
            }}
            publishedUrl={appLastPublishedUrl}
            lastPublishedAt={appLastPublishedAt}
            onPublishTemplate={publishTemplate}
          />
        ) : (
          <PublishAppModal
            app={app}
            onHide={() => {
              setPublishModalOpen(false);
            }}
            publishedUrl={appLastPublishedUrl}
            lastPublishedAt={appLastPublishedAt}
            onPublishApp={preparePreviewLink}
            isLastAppVersionPublished={
              !openPublishModalForBuilderSessionStateId && isLastAppVersionPublished
            }
            isPublishSelectedVersion={isPublishSelectedVersion}
          />
        ))}

      <MyAppsSidebarWithFolders
        isOpen={myAppsSidebarOpen}
        onClose={onCloseSideBar}
        currentApp={app}
      />

      <div className="flex flex-col w-full">
        <div
          className={classNames(
            'flex px-4 py-[7px] items-center border-b border-system-separator',
            {
              'justify-between': !myAppsSidebarOpen && appId,
              'justify-end': myAppsSidebarOpen || !appId,
            }
          )}
        >
          {!myAppsSidebarOpen && appId ? (
            <button
              onClick={onOpenSideBar}
              className={classNames(
                'flex',
                'justify-center',
                'items-center',
                'gap-2 bg-neutral-50',
                'hover:bg-background-secondary',
                'rounded-md px-2 py-1.5',
                'font-medium'
              )}
              data-testid={TEST_ID_SIDEBAR_BUTTON}
            >
              <SideBarLineIcon size={16} />
              {isMobileView ? '' : 'My apps'}
            </button>
          ) : null}
          {userIsAuthenticated && appName && isMobileDevice() && (
            <AppInfo
              appName={appName}
              app={app}
              appLogic={appLogic}
              appLastPublishedAt={appLastPublishedAt}
              appLastPublishedUrl={appLastPublishedUrl}
              updateAppLogic={updateAppLogicManually(loadData, appLogic, app)}
            />
          )}
          {!isMobileDevice() && (
            <div className="flex flex-1 items-center justify-center">
              <TabBar
                items={tabBarItems}
                selectedIndex={selectedItemIndex}
                onSelect={(_item, index) => {
                  setSelectedItemIndex(index);
                  emitTabSwitchEventIfNeeded(index);
                }}
                disabled={!useAuthStore.getState().userIsAuthenticated}
              />
            </div>
          )}
          <AuthNavbar />
        </div>
        {useChatStore.getState().isViewingVersion && (
          <div className="flex items-center bg-sky-50 p-2 px-4 h-[44px] gap-2">
            <div className="flex justify-center w-full gap-2 items-center">
              {viewedVersionDate && (
                <div className="text-xs">
                  <span>Viewing&nbsp;&nbsp;</span>
                  <span>{viewedVersionDate}</span>
                </div>
              )}
              <RevertMessageButton
                buttonText="Recover this version"
                revertFunction={revertAppChange}
                builderSessionStateId={viewedBuilderSessionStateId}
                className="bg-system-success text-white text-xs h-6 px-1.5 rounded justify-center"
                iconSize={16}
              ></RevertMessageButton>
              {!isMobileDevice() && (
                <ActionButton
                  onClick={closeAppViewing}
                  className="bg-system-danger text-white
               text-xs px-1.5 rounded justify-center h-6"
                  size="small"
                >
                  Cancel
                </ActionButton>
              )}
            </div>
            {isMobileDevice() && (
              <div className="flex justify-end cursor-pointer" onClick={closeAppViewing}>
                <CloseLineIcon size={16}></CloseLineIcon>
              </div>
            )}
          </div>
        )}
        <div className="grow flex w-full overflow-hidden">
          {isMobileView /* Mobile View */ ? (
            <div
              className={`w-full`}
              style={{
                height: getVH() - getHeaderHeight(),
              }}
            >
              <div className={`flex flex-col`} style={{ height: getVH() - getHeaderHeight() }}>
                <div className={`sticky top-0 bg-white py-2 px-4 flex`}>
                  {appId && (
                    <div className="flex justify-center w-full" style={{ width: getVW() }}>
                      <TabBar
                        items={mobileTabBarItems}
                        selectedIndex={selectedItemIndex}
                        onSelect={(_item, index) => {
                          setSelectedItemIndex(index);
                          emitTabSwitchEventIfNeeded(index);
                        }}
                        disabled={!userIsAuthenticated}
                      />
                    </div>
                  )}
                </div>
                {process.env.REACT_APP_ENABLE_VSCODE === 'true' && (
                  <div
                    id="mobile-vscode-iframe-container"
                    className={classNames('h-screen', {
                      'absolute top-[-100000px]': selectedItemIndex !== TabBarItemsIndex.APP_CODE,
                      relative: selectedItemIndex === TabBarItemsIndex.APP_CODE,
                    })}
                    ref={mobileVScodeIFrameRef}
                  >
                    {selectedItemIndex === TabBarItemsIndex.APP_CODE &&
                      !isVSCodeLoaded &&
                      // eslint-disable-next-line max-len
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
                      !(window as any).lazyVSCodeNotAvailable && <Loader fullScreen={false} />}
                  </div>
                )}

                {mobileTabBarItems.map((item, index) =>
                  index === selectedItemIndex || item.preserve ? (
                    <div
                      className={classNames(`overflow-y-scroll`, {
                        flex: index === selectedItemIndex,
                        hidden: index !== selectedItemIndex,
                      })}
                      style={{
                        height:
                          isVSCodeAllowed && selectedItemIndex === TabBarItemsIndex.APP_CODE
                            ? '0px'
                            : getVH(),
                      }}
                      key={`mobile_pane_${index}`}
                    >
                      {item.pane}
                    </div>
                  ) : null
                )}
              </div>
            </div>
          ) : (
            /* Desktop View */
            <>
              <div className="flex flex-[3_3_0%] flex-col max-w-[520px]">
                {userIsAuthenticated && appName && (
                  <AppInfo
                    appName={appName}
                    app={app}
                    appLogic={appLogic}
                    appLastPublishedAt={appLastPublishedAt}
                    appLastPublishedUrl={appLastPublishedUrl}
                    updateAppLogic={updateAppLogicManually(loadData, appLogic, app)}
                  />
                )}
                <div
                  ref={chatContainerRef}
                  className={classNames(
                    'flex mt-auto overflow-y-scroll no-scrollbar min-w-[400px] flex-col'
                  )}
                  onClick={onCloseSideBar}
                >
                  <MessageListRenderer
                    app={app}
                    isAllPreviousMessagesLoaded={isAllPreviousMessagesLoaded}
                    onPublishVersion={setOpenPublishModalForBuilderSessionStateId}
                    loadMoreMessages={loadPreviousMessages}
                    submitStarterPrompt={submitStarterPrompt(
                      loadData,
                      app,
                      emitUserPromptResponseReceivedEvent
                    )}
                    activeBuilderSessionStateId={activeBuilderSessionStateId}
                  />
                  <div className="border-t">
                    <Chat
                      isAppRun={false}
                      shouldFocus={shouldFocus}
                      submitUserPrompt={submitUserPromptWithoutWaiting(
                        loadData,
                        app,
                        emitUserPromptResponseReceivedEvent
                      )}
                      onStopRequest={stopRequest}
                    />
                  </div>
                </div>
              </div>

              <div
                onClick={onCloseSideBar}
                className="flex flex-[7_7_0%] flex-col border-l min-w-[300px]"
              >
                {process.env.REACT_APP_ENABLE_VSCODE === 'true' && (
                  <div
                    id="desktop-vscode-iframe-container"
                    className={classNames('h-screen', {
                      'absolute top-[-100000px]': selectedItemIndex !== TabBarItemsIndex.APP_CODE,
                      relative: selectedItemIndex === TabBarItemsIndex.APP_CODE,
                    })}
                    ref={desktopVScodeIFrameRef}
                  >
                    {selectedItemIndex === TabBarItemsIndex.APP_CODE &&
                      !isVSCodeLoaded &&
                      // eslint-disable-next-line max-len
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
                      !(window as any).lazyVSCodeNotAvailable && <Loader fullScreen={false} />}
                  </div>
                )}
                {tabBarItems.map((item, index) =>
                  index === selectedItemIndex || item.preserve ? (
                    <div
                      className={classNames(
                        `justify-center overflow-auto grow`,
                        {
                          flex: index === selectedItemIndex,
                          hidden: index !== selectedItemIndex,
                        },
                        { 'px-0': selectedItemIndex === TabBarItemsIndex.APP_TEST },
                        { 'px-4': selectedItemIndex !== TabBarItemsIndex.APP_TEST }
                      )}
                      style={{
                        maxHeight:
                          index === TabBarItemsIndex.APP_TEST || TabBarItemsIndex.APP_CODE
                            ? getVH()
                            : 0.8 * getVH(),
                      }}
                      key={`desktop_pane_${index}`}
                    >
                      {item.pane}
                    </div>
                  ) : null
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {(isLoading || isOrganizationLoading) && <Loader />}
    </div>
  );
};

export default AppEditor;
