import { create } from 'zustand';
import { JwtToken, UserService } from '../api/generated';
import {
  User,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { FirebaseLazyAuth } from '../utils/firebase';
import { useChatStore } from './chat';
import { userSignedIn, getUserDetails, getSupportUsersId } from '../api/Auth';
import { attemptSetWebFlowCookie } from '../utils/webflowCookie';
import { SHA256 } from 'crypto-js';
import { CURRENT_ACTIVE_APP_ID, INITIAL_PROMPT_KEY } from '../constants';

export interface UserPermissions {
  isUserAllowedToRunApp?: boolean;
  isUserAllowedToPublish?: boolean;
  isUserAllowedToPrompt?: boolean;
  isUserAllowedToConfigureCustomDomain?: boolean;
  isUserAllowedToConfigureProMemory?: boolean;
  hrsToMorePrompts?: number;
}

export interface AuthStoreState {
  firebaseUser: User | null;
  userDetails: JwtToken | null;
  isLazyEmployee: boolean;
  userStatusKnown: boolean;
  userIsAuthenticated: boolean;
  isUserSignedinEventEmitted: boolean;
  userPermissions?: UserPermissions;
  supportUsersIdList: string[];

  refreshUserOnce: () => Promise<User | null>;
  createPasswordlessSignInLink: (emailAddress: string, redirectUrl: string) => Promise<void>;
  completePasswordlessSignIn: (emailAddress: string, emailLink: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  saveRedirectUrl: (url: string) => void;
  navigateToSavedRedirectUrl: () => void;
  getUserDetails: () => Promise<void>;
  syncSupportUsersIdList: () => Promise<void>;
  signOut: () => Promise<void>;
}

const SECONDS_IN_AN_HOUR = 60 * 60 * 1000;
export const REDIRECT_URL_KEY_SESSION_STORAGE = 'redirectUrl';
export const USER_LOGGED_IN_KEY_LOCAL_STORAGE = 'userLoggedIn';
export const SUPPORT_USERS_ID_LIST_LOCAL_STORAGE = 'supportUsersIdList';
const ACTIVE_ORGANIZATION_KEY = 'ACTIVE_ORGANIZATION_ID';

export interface SupportUsersIdLocalStorageData {
  ids: string[];
  timestamp: number;
}

export const attemptUnSetWebFlowCookie = (retryAttempt = 0, maxRetries = 5): Promise<boolean> => {
  return UserService.userClearWebflowCookie()
    .then(() => {
      return true;
    })
    .catch((_e) => {
      if (retryAttempt < maxRetries) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return attemptUnSetWebFlowCookie(retryAttempt + 1, maxRetries);
      } else {
        return false;
      }
    });
};

// eslint-disable-next-line max-lines-per-function
export const useAuthStore = create<AuthStoreState>((set, get) => ({
  firebaseUser: null,
  userDetails: null,
  userStatusKnown: false,
  userIsAuthenticated: false,
  isUserSignedinEventEmitted: false,
  isLazyEmployee: false,
  supportUsersIdList: [],
  // eslint-disable-next-line max-lines-per-function
  refreshUserOnce: async () => {
    const oldUserState = get().firebaseUser;

    // eslint-disable-next-line compat/compat
    const user: User | null = await new Promise((resolve) => {
      const unsubscribe = FirebaseLazyAuth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      });
    });

    set(() => ({
      firebaseUser: user,
      userIsAuthenticated: user !== null,
      userStatusKnown: true,
      isLazyEmployee: user?.email?.endsWith('@datamilk.app'),
    }));

    if (
      !localStorage.getItem(USER_LOGGED_IN_KEY_LOCAL_STORAGE) &&
      !oldUserState?.uid &&
      user?.uid
    ) {
      await userSignedIn();
      localStorage.setItem(USER_LOGGED_IN_KEY_LOCAL_STORAGE, '1');
      await attemptSetWebFlowCookie(0, 1, false);
      set(() => ({ isUserSignedinEventEmitted: true }));
    } else if (!get().userPermissions && !oldUserState?.uid && user?.uid) {
      await attemptSetWebFlowCookie(0, 1, false);
    }

    return user;
  },
  createPasswordlessSignInLink: async (emailAddress: string, redirectUrl: string) => {
    const handledSignInAsSpecialUser = (hashedEmail: string): string => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const listOfSpecialUsers: [string, string][] = JSON.parse(
        process.env.REACT_APP_SPECIAL_TESTING_USERS_HASHED_EMAIL_AND_PASSWORDS || '[]'
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const matching = listOfSpecialUsers.find((specialUser) => {
        if (specialUser.length !== 2) {
          return false;
        }
        const userHashedEmail = specialUser[0];
        if (userHashedEmail && userHashedEmail?.length > 0 && hashedEmail === userHashedEmail) {
          return true;
        }
        return false;
      });
      return matching ? matching[1] : '';
    };
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const hashedEmail = SHA256(emailAddress).toString();
    const testingUserPassword = handledSignInAsSpecialUser(hashedEmail as string);
    if (testingUserPassword) {
      // When the email matches the special email used for e2e tests we sign-in with password.
      await signInWithEmailAndPassword(FirebaseLazyAuth, emailAddress, testingUserPassword);
      await get().refreshUserOnce();
      get().navigateToSavedRedirectUrl();
    } else {
      await sendSignInLinkToEmail(FirebaseLazyAuth, emailAddress, {
        url: redirectUrl,
        handleCodeInApp: true,
      });
    }
  },
  completePasswordlessSignIn: async (emailAddress: string, signInLink: string) => {
    if (isSignInWithEmailLink(FirebaseLazyAuth, signInLink)) {
      await signInWithEmailLink(FirebaseLazyAuth, emailAddress, signInLink);
      await get().refreshUserOnce();
      get().navigateToSavedRedirectUrl();
    } else {
      throw new Error('Invalid sign in link');
    }
  },
  signInWithGoogle: async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(FirebaseLazyAuth, provider);
    await get().refreshUserOnce();
    get().navigateToSavedRedirectUrl();
  },
  signOut: async () => {
    await attemptUnSetWebFlowCookie(0);
    localStorage.removeItem(USER_LOGGED_IN_KEY_LOCAL_STORAGE);
    localStorage.removeItem(ACTIVE_ORGANIZATION_KEY);
    localStorage.removeItem(REDIRECT_URL_KEY_SESSION_STORAGE);
    localStorage.removeItem(INITIAL_PROMPT_KEY);
    localStorage.removeItem(CURRENT_ACTIVE_APP_ID);
    localStorage.removeItem(SUPPORT_USERS_ID_LIST_LOCAL_STORAGE);
    await FirebaseLazyAuth.signOut();
    set(() => ({ isUserSignedinEventEmitted: false }));
    useChatStore.getState().resetError();
    await get().refreshUserOnce();
  },
  navigateToSavedRedirectUrl: () => {
    const savedRedirectUrl = localStorage.getItem(REDIRECT_URL_KEY_SESSION_STORAGE);
    useChatStore.setState(() => ({
      userInputBlocked: false,
    }));
    if (savedRedirectUrl) {
      localStorage.removeItem(REDIRECT_URL_KEY_SESSION_STORAGE);
      location.href = savedRedirectUrl;
    }
  },
  getUserDetails: async () => {
    const userDetails = await getUserDetails();
    set(() => ({ userDetails }));
  },
  syncSupportUsersIdList: async () => {
    let supportUsersData = localStorage.getItem(SUPPORT_USERS_ID_LIST_LOCAL_STORAGE);
    let supportUsersIdList: string[] = [];
    if (!supportUsersData) {
      supportUsersIdList = await getSupportUsersId();
      const currentTime = new Date().getTime();
      const dataToStore: SupportUsersIdLocalStorageData = {
        ids: supportUsersIdList,
        timestamp: currentTime,
      };
      supportUsersData = JSON.stringify(dataToStore);
      localStorage.setItem(SUPPORT_USERS_ID_LIST_LOCAL_STORAGE, supportUsersData);
    } else {
      const parsedData = JSON.parse(supportUsersData) as SupportUsersIdLocalStorageData;
      if (parsedData && parsedData.ids) {
        const currentTime = new Date().getTime();
        const elapsedTime = currentTime - parsedData.timestamp;
        // refresh cached users list if an hour has elapsed
        if (elapsedTime > SECONDS_IN_AN_HOUR) {
          supportUsersIdList = await getSupportUsersId();
          supportUsersData = JSON.stringify({ ids: supportUsersIdList, timestamp: currentTime });
          localStorage.setItem(SUPPORT_USERS_ID_LIST_LOCAL_STORAGE, supportUsersData);
        } else {
          supportUsersIdList = parsedData.ids;
        }
      }
    }
    set(() => ({ supportUsersIdList }));
  },
  saveRedirectUrl: (url: string) => {
    localStorage.setItem(REDIRECT_URL_KEY_SESSION_STORAGE, url);
  },
}));
