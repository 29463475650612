import React from 'react';
import RichPromptInput from './RichPromptInput';
import RichPromptInputLoader from './RichPromptInputLoader';
import RichPromptActionList, { RichPromptAction } from './RichPromptActionList';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { RevertFunction, useChatStore } from '../../store/chat';
import ActionButton from '../base/ActionButton';
import SparklingIcon from '../../assets/sparkling.svg';

interface RichPromptProps {
  currentPrompt: string;
  changeCurrentPrompt: (string) => void;
  submitUserPrompt: (string) => void;
  handleUserCancel: () => Promise<void>;
  handleInputFocus: (focus: boolean) => void;
  loading?: boolean;
  blocked?: boolean;
  displayActions?: boolean;
  revertFunction?: RevertFunction;
  actions?: RichPromptAction[];
  statusMessage?: string | null;
  hideLoadingStop: boolean;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  isAppRun?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const RichPrompt = (props: RichPromptProps) => {
  const dontRenderPromptInput = props.blocked && !props.loading;
  const sendMessage = (input: string) => {
    if (input.trim() !== '') {
      props?.submitUserPrompt(input);
    }
  };

  const onStop = () => {
    if (props.loading) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      props?.handleUserCancel();
    }
  };

  // eslint-disable-next-line max-lines-per-function
  const renderPromptInput = () => {
    if (dontRenderPromptInput) {
      return <></>;
    }

    return (
      <div className="flex flex-col w-full">
        {props.loading ? (
          <div className={classNames('flex')}>
            <RichPromptInputLoader
              onStop={onStop}
              hideStop={props.hideLoadingStop}
              message={props.statusMessage}
            />
          </div>
        ) : null}

        <div
          className={classNames('flex flex-col w-full relative', {
            hidden: props.loading,
            'gap-2': props.displayActions && props.actions?.length !== 0,
          })}
          onFocus={() => props.handleInputFocus(true)}
        >
          <div className={classNames('flex', { hidden: !props.displayActions })}>
            <RichPromptActionList actions={props.actions ?? []} />
          </div>

          <RichPromptInput
            textareaRef={props.textareaRef}
            currentPrompt={props.currentPrompt}
            changeCurrentPrompt={props.changeCurrentPrompt}
            onSend={sendMessage}
            isAppRun={props.isAppRun}
          />
        </div>
      </div>
    );
  };

  const canShowAbilitiesButton =
    process.env.REACT_APP_ENABLE_ABILTIES_IFRAME === 'true' &&
    !props.loading &&
    !props.isAppRun &&
    !dontRenderPromptInput;

  return (
    <div className={classNames('flex min-h-0 grow flex-col justify-end flex-nowrap z-50')}>
      <div
        className={classNames('flex w-full px-4 pt-4 flex-col', {
          'mb-8': !isMobileDevice() && !dontRenderPromptInput,
          'pb-6': isMobileDevice() && !dontRenderPromptInput,
        })}
      >
        {renderPromptInput()}
        {canShowAbilitiesButton && (
          <ActionButton
            buttonType="primary"
            fillState="light"
            className="mt-3 w-24 !rounded-xl"
            onClick={() => useChatStore.setState({ showAbilitiesIframe: true })}
          >
            <img src={SparklingIcon as string} alt="Sparkling Icon" />
            Abilities
          </ActionButton>
        )}
      </div>
    </div>
  );
};

export default RichPrompt;
