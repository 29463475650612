import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AddFillIcon from 'remixicon-react/AddFillIcon';
import ArrowRightIcon from 'remixicon-react/ArrowRightSLineIcon';
import FolderAddFillIcon from 'remixicon-react/FolderAddLineIcon';
import Loader4LineIcon from 'remixicon-react/Loader4LineIcon';
import SideBarFillIcon from 'remixicon-react/SideBarFillIcon';

import { createFolder, getAllFolders } from '../../api/AppFoldersApi';
import { getMyApps } from '../../api/BuilderApi';
import { LazyAppWithMinimalAppVersionWithSessionStateId, LazyAppFolder } from '../../api/generated';
import AuthNavbar from '../../auth/AuthNavbar';
import { useAppStore } from '../../store/app';
import { useOrganizationStore } from '../../store/organization';
import { getLastPublishedVersionFromVersions } from '../../utils';
import ActionButton from '../base/ActionButton';
import Button from '../base/Button';
import ChatBannerPicker from '../ChatBannerPicker';
import CollapsibleSidebar from '../CollapsibleSidebar';
import AppAdvanceFilters from './AppFilters/AdvanceFilters';
import AppSearchInputFilter from './AppFilters/SearchInputFilter';
import {
  applyAppsAndTemplatesFilter,
  applyArchivedFilter,
  applyPublishedStatusFilter,
} from './AppFilters/utils';
import FolderList from './FolderList';
import { applySearchFilterToAppsList, sortByName } from './utils';

interface MyAppsSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  currentApp?: LazyAppWithMinimalAppVersionWithSessionStateId | null;
}

// eslint-disable-next-line max-lines-per-function, max-statements
const MyAppsSidebar = ({ isOpen, onClose }: MyAppsSidebarProps) => {
  const [apps, setApps] = useState<LazyAppWithMinimalAppVersionWithSessionStateId[]>([]);
  const [folders, setFolders] = useState<LazyAppFolder[]>([]);
  const [appsFilteredBySearchInput, setAppsFilteredBySearchInput] = useState<
    LazyAppWithMinimalAppVersionWithSessionStateId[]
  >([]);

  const [foldersFilteredBySearchInput, setFoldersFilteredBySearchInput] = useState<LazyAppFolder[]>(
    []
  );
  const [showAdvanceFilters, setShowAdvanceFilters] = useState(false);
  const [archivedFilter, setArchivedFilter] = useState('non-archived');
  const [publishedStatusFilter, setPublishedStatusFilter] = useState('all');
  const [searchFilter, setSearchFilter] = useState('');
  const [showNewFolderInput, setShowNewFolderInput] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [appsAndTemplatesFilter, setAppsAndTemplatesFilter] = useState('all');
  const { activeOrganization } = useOrganizationStore();

  const updatePublishedStatusesMap = (apps: LazyAppWithMinimalAppVersionWithSessionStateId[]) => {
    const updatedLastPublishedVersionsMap = useAppStore.getState().publishedAppVersionsMap;
    apps.forEach((app: LazyAppWithMinimalAppVersionWithSessionStateId) => {
      const lastPublishedVersion = getLastPublishedVersionFromVersions(app.app_versions);
      updatedLastPublishedVersionsMap.set(app.id, !!lastPublishedVersion);
    });
    useAppStore.setState({ publishedAppVersionsMap: updatedLastPublishedVersionsMap });
  };

  const refreshApps = async () => {
    const allApps = await getMyApps(activeOrganization?.id);
    updatePublishedStatusesMap(allApps);

    // Apply filters to the apps
    const filteredApps = allApps
      .filter(applyArchivedFilter(archivedFilter))
      .filter(applyAppsAndTemplatesFilter(appsAndTemplatesFilter))
      .filter(applyPublishedStatusFilter(publishedStatusFilter));
    setApps(filteredApps);
  };

  const refreshFolders = async () => {
    const allFolders = await getAllFolders(activeOrganization?.id);
    useOrganizationStore.setState({ appFolders: allFolders });

    allFolders.forEach((folder) => folder.apps && updatePublishedStatusesMap(folder.apps));

    // Apply filters to the folders
    const filteredFolders = sortByName(allFolders.filter(applyArchivedFilter(archivedFilter)));
    // Apply filters to the apps in the folders
    filteredFolders.forEach(
      (folder) =>
        (folder.apps = folder.apps
          ?.filter(applyArchivedFilter(archivedFilter))
          .filter(applyAppsAndTemplatesFilter(appsAndTemplatesFilter))
          .filter(applyPublishedStatusFilter(publishedStatusFilter)))
    );
    setFolders(filteredFolders);
  };

  const handleCreateApp = () => {
    window.open('/?new_app=true', '_blank');
  };

  const loadAppsAndFolders = async (): Promise<void> => {
    setIsloading(true);
    // eslint-disable-next-line compat/compat
    await Promise.all([
      refreshApps().catch((e) => toast.error(e as string)),
      refreshFolders().catch((e) => toast.error(e as string)),
    ])
      .then(() => {
        setIsloading(false);
        return null;
      })
      .catch(() => toast.error('Something went wrong in loading the apps. Please try again!'));
  };

  useEffect(() => {
    if (isOpen) {
      (async () => await loadAppsAndFolders())();
    }
  }, [
    archivedFilter,
    appsAndTemplatesFilter,
    publishedStatusFilter,
    activeOrganization?.id,
    isOpen,
  ]);

  useEffect(() => {
    setAppsFilteredBySearchInput(
      searchFilter.trim() !== '' ? applySearchFilterToAppsList(apps, searchFilter) : apps
    );
    setFoldersFilteredBySearchInput(
      searchFilter.trim() !== ''
        ? folders
            .map(({ ...folder }) => {
              folder.apps = applySearchFilterToAppsList(folder.apps || [], searchFilter);
              return folder;
            })
            .filter((folder) => !!folder.apps?.length)
        : folders
    );
  }, [searchFilter, apps, folders]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const inputElement = e.target as HTMLInputElement;
      createFolder(inputElement.value, activeOrganization?.id)
        .then((newFolder) => {
          setShowNewFolderInput(false);
          setFolders(sortByName([newFolder, ...folders]));
          toast.success('New folder created successfully.');
          return null;
        })
        .catch(() => {
          // Handle errors
          toast.error('Please make sure its a unique folder name.');
        });
    }
  };

  const renderLoader = () => (
    <div
      className={
        'flex items-center h-full w-full justify-center ' +
        'absolute z-[1000] bg-opacity-20 bg-black'
      }
    >
      <Loader4LineIcon className="animate-spin text-system-accent" size="4rem" />
    </div>
  );

  return (
    <CollapsibleSidebar open={isOpen}>
      <div className="flex flex-col items-start h-full gap-3 bg-neutral-50 relative">
        {isLoading && renderLoader()}

        <div className="p-3 flex w-full gap-2 items-center z-[2000]">
          <div className="flex-1 items-center">
            <AuthNavbar sidebar={true} />
          </div>
          <Button onClick={() => onClose()} className="bg-neutral-50">
            <SideBarFillIcon size={20} />
          </Button>
        </div>

        <ChatBannerPicker />
        <AppSearchInputFilter
          searchFilter={searchFilter}
          onSearchFilterChange={setSearchFilter}
          showAdvanceFilters={showAdvanceFilters}
          onShowFiltersChange={setShowAdvanceFilters}
        />

        {showAdvanceFilters && (
          <AppAdvanceFilters
            archivedFilter={archivedFilter}
            publishedStatusFilter={publishedStatusFilter}
            appsAndTemplatesFilter={appsAndTemplatesFilter}
            onArchivedFilterChange={setArchivedFilter}
            onPublishedStatusFilterChange={setPublishedStatusFilter}
            onAppsAndTemplatesFilterChange={setAppsAndTemplatesFilter}
          />
        )}

        <div className="flex w-full gap-2 items-center justify-between pl-4 pr-3 py-2">
          <span className="font-semibold label-primary text-base">My Apps</span>
          <Button onClick={() => setShowNewFolderInput(true)} className="bg-neutral-50">
            <FolderAddFillIcon size={20} />
          </Button>
        </div>

        {showNewFolderInput && (
          <div className="flex w-full px-3">
            <div
              className={
                'flex flex-1 gap-2 items-center bg-white border ' +
                'border-system-separator rounded p-1.5'
              }
            >
              <ArrowRightIcon size={20} />
              <input
                type="text"
                placeholder="Folder Name"
                className="flex-1 outline-none"
                onBlur={() => setShowNewFolderInput(false)}
                onKeyDown={(e) => handleKeyDown(e)}
              ></input>
            </div>
          </div>
        )}

        <FolderList
          apps={appsFilteredBySearchInput}
          folders={foldersFilteredBySearchInput}
          onAppMoved={loadAppsAndFolders}
          closeSidebar={onClose}
        />

        <div className="flex items-center justify-center w-full self-end z-[2000]">
          <ActionButton
            onClick={handleCreateApp}
            className="flex flex-1 m-4 mb-8 !bg-system-accent !rounded px-2 py-1"
          >
            <AddFillIcon size={20} className={'inline align-text-bottom'} /> Create new app
          </ActionButton>
        </div>
      </div>
    </CollapsibleSidebar>
  );
};

export default MyAppsSidebar;
